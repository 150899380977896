<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.deleteAccount.title')" />

      <b-row>
        <div class="settings__form">
          <div class="settings__warning">
            <span class="settings__font settings__font--warning">
              {{ $t("general.settingsPages.deleteAccount.warningText") }}
            </span>
          </div>

          <div class="settings__input">
            <CInput
              type="password"
              name="password"
              theme="settings"
              class="settings__field"
              v-model="password"
              :label="$t('general.settingsPages.deleteAccount.placeholder')"
              :rules="'required|min:8'"
            />
          </div>
        </div>

        <div class="settings__button-wrapper">
          <button
            type="button"
            @click.prevent="saveSettings"
            class="base-button settings__button"
          >
            <span class="base-button__font">
              {{ $t("general.settingsPages.deleteAccount.button") }}
            </span>
          </button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import PageHeader from "@/layout/PageHeader.vue";
import CInput from "@/features/ui/СInput.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  name: "PageDeleteAccount",
  components: {
    CInput,
    PageHeader,
  },
  mixins: [waitRequest, validationError],

  data: function () {
    return {
      password: "",
    };
  },

  methods: {
    saveSettings() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/profile/delete",
          {
            password: this.password,
            _method: "DELETE",
          },
          () => {
            this.logOut();
          },
          (errors) => {
            console.log("errors", errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );
      this.$saveToken(null);
      this.$saveUser(null);
      this.$router.push({ name: "login" });
      //location = process.env.VUE_APP_APP_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__form {
    margin-top: em(20);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }

  &__button {
    margin-top: em(17);
    margin-right: em(15);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(9);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(18);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {
  }

  &__warning {
  }

  &__font {
    &--warning {
      font-family: $font-default;
      font-size: em(14);
      color: $app-red;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
